export default {
  computed: {
    pageTemplate() {
      switch (this.category?.template) {
        case "catalog":
          return () => import("@/components/category/category-main.vue");
        case "products":
          return () => import("@/components/category/category-med.vue");
        case "special":
          return () => import("@/components/category/category-med.vue");
        case "custom":
          return () => import("@/components/category/category-med.vue");
        default:
          return () =>
            import(`@/components/category/${this.category?.template}.vue`);
      }
    }
  }
};
